import { Html5QrcodeScanner } from "html5-qrcode";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import CryptoJS from "crypto-js";
import axios from "axios";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";

const Scanner = () => {
  const [scanResult, setScanResult] = useState(null);
  const [apiData, setApiData] = useState(null); // State to store API data
  const [loading, setLoading] = useState(false);
  const [token] = useState(localStorage.getItem("jwtToken") || "");
  const navigate = useNavigate();

  useEffect(() => {
    const scanner = new Html5QrcodeScanner("reader", {
      qrbox: {
        width: 250,
        height: 250,
      },
      fps: 10,
      rememberLastUsedCamera: true,
      showTorchButtonIfSupported: true,
      showZoomSliderIfSupported: true,
      defaultZoomValueIfSupported: 1,
    });

    scanner.render(success, error);

    // Hide info icon on scanner
    document.querySelector("#reader img").style.display = "none";
    
    const calculateAsciiSum = (str) => {
      let sum = 0;
      for (let i = 0; i < str.length; i++) {
        sum += str.charCodeAt(i); // Sum up ASCII values of each character
      }
      return sum;
    };

    function success(result) {
      scanner.clear();
      try {
        debugger;
        setLoading(true); // Start loading
        var bytes = CryptoJS.AES.decrypt(result, "oceangold");
        var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        setScanResult(decryptedData);
        console.log(calculateAsciiSum(decryptedData));
        //let sumDecryptedData = calculateAsciiSum(decryptedData);

        // Axios GET request with Bearer token
        axios
          .get(`https://strapi-production-b6f7.up.railway.app/api/qr-codes`, {
            params: {
              filters: {
                code: {
                  $eq: decryptedData,
                },
              },
              populate: "*",
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            response.data.data[0]
              ? setApiData(
                  response.data.data[0].attributes.form_id.data.attributes
                )
              : alert("No data against provided QR");
            setLoading(false); // End loading
          })
          .catch((err) => {
            console.error("Error fetching API data:", err);
            setLoading(false); // End loading
          });
      } catch (err) {
        console.error("Decryption error:", err);
        setLoading(false); // End loading
      }
    }

    function error(err) {
      console.warn(err);
    }
  }, [token]);

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ p: 3, mt: 4 }}>
        <Typography variant="h4" gutterBottom>
          QR Code Scanner
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
          <div id="reader"></div>
        </Box>
        {scanResult && (
          <Paper elevation={3} sx={{ p: 2, mb: 3 }}>
            <Typography variant="h6">Scanned Data</Typography>
            <Divider sx={{ mb: 2 }} />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1">
                  <strong>Name:</strong> {apiData?.name}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        )}
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        ) : (
          apiData && (
            <Paper elevation={3} sx={{ p: 2, mt: 3 }}>
              <Typography variant="h6">API Data</Typography>
              <Divider sx={{ mb: 2 }} />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    <strong>Processing Method:</strong>{" "}
                    {apiData.processingMethod}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    <strong>Supplier:</strong> {apiData.supplier}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    <strong>Processing Supervisor:</strong>{" "}
                    {apiData.processingSupervisor}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    <strong>Packing Supervisor:</strong>{" "}
                    {apiData.packingSupervisor}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    <strong>Catch Method:</strong> {apiData.catchMethod}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    <strong>Production Time:</strong>{" "}
                    {new Date(apiData.productionTime).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    <strong>Expiry Date:</strong>{" "}
                    {new Date(apiData.expiry).toLocaleDateString()}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    <strong>Location:</strong> {apiData.location}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          )
        )}
        <Box sx={{ mt: 3 }}>
          <Button variant="contained" onClick={() => navigate("/")}>
            Back
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default Scanner;
